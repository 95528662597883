<template>
  <transition name="fade">
    <div v-show="loaded">
      <v-row
        v-if="usaDictado || (variables && variables.length)"
        class="px-4"
        justify="end"
      >
        <v-col
          v-if="usaDictado"
          cols="4"
          class="px-0 py-0"
          align="end"
          align-self="center"
        >
          <v-btn
            @click="onDictado"
            dense
            outlined
            color="primary"
            class="btn-dictar mb-1"
          >
            <v-icon small>{{ microIcon }}</v-icon>
            Dictar diagnóstico</v-btn
          >
        </v-col>
        <!-- <v-col class="pa-0 max-h" v-if="variables && variables.length" cols="4" :offset="usaDictado ? 0 : 8">
          
        </v-col> -->
      </v-row>

      <!-- TOOLBAR -->
      <div class="toolbar pt-1" v-if="editor">
        <v-row class="px-4 py-1 max-h">
          <v-select
            class="heading-combo px-0 mr-1"
            dense
            solo
            label="Párrafo"
            v-model="headingSelected"
            :items="headingItems"
            @change="onChangeHeading"
            item-text="value"
            item-value="id"
            return-object
          >
            <template slot="item" slot-scope="{ item }">
              <span :style="item.style">{{ item.value }}</span>
            </template>
          </v-select>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                class="px-0 mt-2"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBold()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('bold') }"
                v-on="on"
              >
                <v-icon>{{ boldIcon }}</v-icon></v-btn
              >
            </template>
            <span>Negrita</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                class="px-0 mt-2"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('italic') }"
                v-on="on"
              >
                <v-icon>{{ italicIcon }}</v-icon></v-btn
              >
            </template>
            <span>Cursiva</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                class="px-0 mt-2"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleUnderline()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('underline') }"
                v-on="on"
              >
                <v-icon>{{ underlineIcon }}</v-icon></v-btn
              >
            </template>
            <span>Subrayado</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                class="px-0 mt-2"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('strike') }"
                v-on="on"
              >
                <v-icon>{{ strikeIcon }}</v-icon></v-btn
              >
            </template>
            <span>Tachado</span>
          </v-tooltip>
          <!-- COLOR TEXTO -->
          <v-menu
            v-model="showColorText"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <!-- @mouseover="showColorText = true, showColorFill = false"
                @mouseleave="showColorText = false" -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="px-0 mt-2"
                @click="onClickColorText"
                v-bind="attrs"
                v-on="on"
                @mouseover="(showColorText = true), (showColorFill = false)"
                @mouseleave="closeMenu"
                :class="{ 'is-active': editor.isActive('textStyle') }"
              >
                <v-icon>{{ colorTextIcon }}</v-icon>
              </v-btn>
            </template>
            <ColorPicker
              :title="'Color de texto'"
              v-if="showColorText"
              @mouseleave="showColorText = false"
              @onSelect="onColorSelect($event, 'colorText')"
              @onClear="
                editor
                  .chain()
                  .focus()
                  .unsetColor()
                  .run(),
                  (showColorText = false)
              "
            />
          </v-menu>
          <!-- COLOR RELLENO -->
          <v-menu
            v-model="showColorFill"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="px-0 mt-2"
                @click="onClickColorFill"
                v-bind="attrs"
                v-on="on"
                @mouseover="(showColorFill = true), (showColorText = false)"
                @mouseleave="closeMenu"
                :class="{ 'is-active': editor.isActive('highlight') }"
              >
                <v-icon>{{ colorFillIcon }}</v-icon>
              </v-btn>
            </template>
            <ColorPicker
              :title="'Color de relleno'"
              v-if="showColorFill"
              @onSelect="onColorSelect($event, 'colorFill')"
              @onClear="
                editor
                  .chain()
                  .focus()
                  .unsetHighlight()
                  .run(),
                  (showColorFill = false)
              "
            />
          </v-menu>

          <v-divider vertical class="mt-0 mx-1"></v-divider>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('left')
                    .run()
                "
                :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
                v-on="on"
              >
                <v-icon size="20">{{ alignLeftIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Alinear izquierda</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('center')
                    .run()
                "
                :class="{
                  'is-active': editor.isActive({ textAlign: 'center' })
                }"
                v-on="on"
              >
                <v-icon size="20">{{ alignCenterIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Centrar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('right')
                    .run()
                "
                :class="{
                  'is-active': editor.isActive({ textAlign: 'right' })
                }"
                v-on="on"
              >
                <v-icon size="20">{{ alignRightIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Alinear derecha</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('justify')
                    .run()
                "
                :class="{
                  'is-active': editor.isActive({ textAlign: 'justify' })
                }"
                v-on="on"
              >
                <v-icon size="20">{{ alignJustifyIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Justificar</span>
          </v-tooltip>

          <v-divider vertical class="mt-0 mx-1"></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBulletList()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('bulletList') }"
                v-on="on"
              >
                <v-icon>{{ ulIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Lista</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleOrderedList()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('orderedList') }"
                v-on="on"
              >
                <v-icon>{{ olIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Lista ordenada</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleTaskList()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('taskList') }"
                v-on="on"
              >
                <v-icon>{{ listCheckboxIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Lista de tareas</span>
          </v-tooltip>

          <v-divider vertical class="mt-0 mx-1"></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="setLink"
                :class="{ 'is-active': editor.isActive('link') }"
                v-on="on"
              >
                <v-icon>{{ linkIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Enlaces</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBlockquote()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('blockquote') }"
                v-on="on"
              >
                <v-icon>{{ quoteIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cita</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .setHorizontalRule()
                    .run()
                "
                v-on="on"
              >
                <v-icon>{{ minusIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Linea horizontal</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleCodeBlock()
                    .run()
                "
                :class="{ 'is-active': editor.isActive('codeBlock') }"
                v-on="on"
              >
                <v-icon>{{ codeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Vista de código</span>
          </v-tooltip>

          <v-divider vertical class="mt-0 ml-1"></v-divider>

          <!-- TABLAS -->
          <v-menu v-model="menuTablasGlobal" offset-y>
            <template #activator="{ on: onMenu }">
              <v-tooltip top>
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    icon
                    class="px-0 mt-1"
                    v-on="{ ...onMenu, ...onTooltip }"
                  >
                    <v-icon @click="onAddTableClick">{{ addTableIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Tablas</span>
              </v-tooltip>
            </template>
            <div class="contenedor-lista pa-2">
              <v-menu v-model="menuTablasInsert" offset-x>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <span>Insertar tabla</span>
                  </div>
                </template>
                <div class="contenedor-lista pa-2">
                  <div class="cuadricula">
                    <div
                      v-for="(fila, rowIndex) in 7"
                      :key="rowIndex"
                      class="max-w"
                    >
                      <div
                        v-for="(cuadro, colIndex) in 7"
                        :key="colIndex"
                        :class="{
                          cuadrado: true,
                          hovered: isHovered(rowIndex, colIndex)
                        }"
                        class="my-1"
                        @mouseover="resaltarFilaColumna(rowIndex, colIndex)"
                        @click="
                          onClickInsertarTabla(), (menuTablasGlobal = false)
                        "
                      ></div>
                    </div>
                  </div>
                  <v-row justify="end" class="px-4">
                    {{ qRow }} x {{ qCol }}
                  </v-row>
                </div>
              </v-menu>

              <v-divider class="my-1"></v-divider>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .addColumnBefore()
                    .run()
                "
              >
                <span>Agregar columna antes</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .addColumnAfter()
                    .run()
                "
              >
                <span>Agregar columna después</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .deleteColumn()
                    .run()
                "
              >
                <span>Eliminar columna</span>
              </div>
              <v-divider class="my-1"></v-divider>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .addRowBefore()
                    .run()
                "
              >
                <span>Agregar fila antes</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .addRowAfter()
                    .run()
                "
              >
                <span>Agregar fila después</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .deleteRow()
                    .run()
                "
              >
                <span>Eliminar fila</span>
              </div>
              <v-divider class="my-1"></v-divider>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .mergeOrSplit()
                    .run()
                "
              >
                <span>Combinar/separar celdas</span>
              </div>
              <v-divider class="my-1"></v-divider>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .deleteTable()
                    .run()
                "
              >
                <span>Eliminar tabla</span>
              </div>
            </div>
          </v-menu>

          <!-- IMAGENES -->
          <v-tooltip top v-if="!hideImage">
            <template #activator="{ on }">
              <v-btn icon class="px-0 mt-1" @click="openModalImage" v-on="on">
                <v-icon>{{ imageIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Agregar imágen</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .undo()
                    .run()
                "
                :disabled="
                  !editor
                    .can()
                    .chain()
                    .focus()
                    .undo()
                    .run()
                "
                v-on="on"
              >
                <v-icon>{{ undoIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Atrás</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .redo()
                    .run()
                "
                :disabled="
                  !editor
                    .can()
                    .chain()
                    .focus()
                    .redo()
                    .run()
                "
                v-on="on"
              >
                <v-icon>{{ redoIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Adelante</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="px-0 mt-2"
                small
                @click="
                  editor
                    .chain()
                    .focus()
                    .unsetAllMarks()
                    .run()
                "
                v-on="on"
              >
                <v-icon>{{ clearFormatIcon }}</v-icon></v-btn
              >
            </template>
            <span>Limpiar formatos</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-select
            v-if="variables && variables.length"
            ref="comboVariables"
            class="combo-variables"
            solo
            dense
            v-model="variableSelected"
            :items="variables"
            clearable
            item-text="nombre"
            item-value="variable"
            :return-object="usaAdicionales ? true : false"
            :label="labelVariables ? labelVariables : 'Insertar variable'"
            @change="insertarVariable(variableSelected)"
          ></v-select>
        </v-row>
      </div>
      <!-- <v-divider class="mt-0 mb-2"></v-divider> -->
      <!-- EDITOR -->
      <div class="text-editor-wraper" @click="onClickEditor">
        <editor-content :editor="editor" :id="`text-editor-${uniqueId}`" />
      </div>
      <!-- <div>
        HTML
        {{getContent}}
      </div> -->

      <!-- INSERTAR IMAGEN -->
      <v-dialog v-model="showInputImage" max-width="500px">
        <AddImageModal
          v-if="showInputImage"
          @cancelar="showInputImage = false"
          @on-updated-file="onUpdatedFile"
          @insert-image-from-url="insertImageFromUrl"
        />
      </v-dialog>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";

// import enums from '../../utils/enums';
import axios from "axios";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import ResizableImage from "./resizable-image.js";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import rules from "@/utils/helpers/rules";
import ColorPicker from "./ColorPicker";
import Link from "@tiptap/extension-link";
import AddImageModal from "./AddImageModal.vue";

export default {
  name: "TextEditor",
  components: {
    EditorContent,
    ColorPicker,
    AddImageModal
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    cancel: {
      type: Boolean,
      default: false
    },
    uniqueIdForThisEdit: {
      type: Number,
      default: Date.now()
    },
    variables: {
      type: Array
    },
    usaAdicionales: {
      type: Boolean,
      default: false
    },
    labelVariables: {
      type: String,
      default: ""
    },
    usaDictado: {
      type: Boolean,
      default: false
    },
    noUsaExtras: {
      type: Boolean,
      default: false
    },
    hideImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoveredRow: -1,
      hoveredCol: -1,
      loaded: false,
      rules: rules,
      microIcon: enums.icons.MICRO,
      pencilIcon: enums.icons.EDIT_CIRCLE,
      boldIcon: enums.icons.FORMAT_BOLD,
      italicIcon: enums.icons.FORMAT_ITALIC,
      underlineIcon: enums.icons.FORMAT_UNDERLINE,
      strikeIcon: enums.icons.FORMAT_STRIKE,
      clearFormatIcon: enums.icons.FORMAT_CLEAR,
      undoIcon: enums.icons.UNDO,
      redoIcon: enums.icons.REDO,
      imageIcon: enums.icons.ADD_IMAGE,
      olIcon: enums.icons.OL,
      ulIcon: enums.icons.UL,
      codeIcon: enums.icons.CODE,
      quoteIcon: enums.icons.QUOTE,
      minusIcon: enums.icons.REMOVE,
      listCheckboxIcon: enums.icons.LIST_CHECKBOX,
      alignLeftIcon: enums.icons.ALIGN_LEFT,
      alignRightIcon: enums.icons.ALIGN_RIGHT,
      alignCenterIcon: enums.icons.ALIGN_CENTER,
      alignJustifyIcon: enums.icons.ALIGN_JUSTIFY,
      addTableIcon: enums.icons.TABLE_ADD,
      colorTextIcon: enums.icons.COLOR_TEXT,
      colorFillIcon: enums.icons.COLOR_FILL,
      linkIcon: enums.icons.LINK,
      uniqueId: this.uniqueIdForThisEdit,
      urlImg: "",
      editor: null,
      variableSelected: null,
      showInputImage: false,
      isLoading: false,
      imgUploadedAlto: "",
      imgUploadedAncho: "",
      file: null,
      myImage: null,
      headingSelected: null,
      showColorText: false,
      showColorFill: false,
      showMenuTables: false,
      hasTable: false,
      menuTablasGlobal: false,
      menuTablasInsert: false,
      qRow: 0,
      qCol: 0,
      withHeaderRow: true,
      headingItems: [
        { id: "p", value: "Párrafo", level: 0, style: { "font-size": "14px" } },
        {
          id: "h6",
          value: "Título 6",
          level: 6,
          style: { "font-size": "16px" }
        },
        {
          id: "h5",
          value: "Título 5",
          level: 5,
          style: { "font-size": "18px" }
        },
        {
          id: "h4",
          value: "Título 4",
          level: 4,
          style: { "font-size": "20px" }
        },
        {
          id: "h3",
          value: "Título 3",
          level: 3,
          style: { "font-size": "22px" }
        },
        {
          id: "h2",
          value: "Título 2",
          level: 2,
          style: { "font-size": "24px" }
        },
        {
          id: "h1",
          value: "Título 1",
          level: 1,
          style: { "font-size": "26px" }
        }
      ],
      asIdListTmp: []
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem.configure({
          nested: true
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"]
        }),
        ResizableImage.configure({
          allowBase64: true,
          inline: true
        }),
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell,
        Link.configure({
          openOnClick: true
        })
      ],
      onUpdate: () => {
        this.$emit("update:value", this.editor.getHTML());
      }
    });
    setTimeout(() => (this.loaded = true), 1000);
  },
  computed: {
    getContent() {
      return this.editor?.getHTML();
    }
  },
  watch: {
    value(val) {
      if (this.editor.getHTML() === val) return;
      this.editor.commands.setContent(val, false);
    },
    cancel(val) {
      if (val) {
        this.cancelTextEditor();
      }
    }
  },
  methods: {
    ...mapActions({
      deleteImgUploaded: "shared/deleteImgUploaded"
    }),
    closeMenu(e) {
      if (!e.relatedTarget || !e.relatedTarget.closest(".v-menu__content")) {
        this.showColorText = false;
        this.showColorFill = false;
      }
    },
    resaltarFilaColumna(rowIndex, colIndex) {
      this.hoveredRow = rowIndex;
      this.hoveredCol = colIndex;
      this.qRow = colIndex + 1;
      this.qCol = rowIndex + 1;
    },
    isHovered(rowIndex, colIndex) {
      return rowIndex <= this.hoveredRow && colIndex <= this.hoveredCol;
    },
    onAddTableClick() {
      // reviso si hay alguna tabla en el html content
      let content = this.editor.getHTML();
      if (content.includes("<table>")) this.hasTable = true;
      else this.hasTable = false;
    },
    onClickInsertarTabla() {
      this.editor
        .chain()
        .focus()
        .insertTable({
          rows: this.qRow,
          cols: this.qCol,
          withHeaderRow: this.withHeaderRow
        })
        .run();
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt(
        "Agregar URL (ej. https://ejemplo.com)",
        previousUrl
      );

      // cancelled
      if (url === null) return;

      // empty
      if (url === "") {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .unsetLink()
          .run();
        return;
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    onClickEditor() {
      // setea el combo parrafo segun el valor del texto donde caiga el cursor
      if (this.editor.isActive("paragraph")) {
        this.headingSelected = { id: "p", value: "Párrafo", level: 0 };
        return;
      }
      if (this.editor.isActive("heading", { level: 1 })) {
        this.headingSelected = { id: "h1", value: "H1", level: 1 };
        return;
      }
      if (this.editor.isActive("heading", { level: 2 })) {
        this.headingSelected = { id: "h2", value: "H2", level: 2 };
        return;
      }
      if (this.editor.isActive("heading", { level: 3 })) {
        this.headingSelected = { id: "h3", value: "H3", level: 3 };
        return;
      }
      if (this.editor.isActive("heading", { level: 4 })) {
        this.headingSelected = { id: "h4", value: "H4", level: 4 };
        return;
      }
      if (this.editor.isActive("heading", { level: 5 })) {
        this.headingSelected = { id: "h5", value: "H5", level: 5 };
        return;
      }
      if (this.editor.isActive("heading", { level: 6 })) {
        this.headingSelected = { id: "h6", value: "H6", level: 6 };
        return;
      }
    },
    onClickColorText() {
      if (this.editor.isActive("textStyle")) {
        this.editor
          .chain()
          .focus()
          .unsetColor()
          .run();
        this.showColorText = false;
      }
    },
    onClickColorFill() {
      if (this.editor.isActive("highlight")) {
        this.editor
          .chain()
          .focus()
          .unsetHighlight()
          .run();
        this.showColorText = false;
      }
    },
    onColorSelect(colorSelected, from) {
      if (from === "colorText") {
        this.editor
          .chain()
          .focus()
          .setColor(colorSelected)
          .run();
        this.showColorText = false;
      } else {
        this.editor
          .chain()
          .focus()
          .toggleHighlight({ color: colorSelected })
          .run();
        this.showColorFill = false;
      }
    },
    onChangeHeading() {
      if (!this.headingSelected) return;
      if (this.headingSelected.level == 0) {
        this.editor
          .chain()
          .focus()
          .setParagraph()
          .run();
        return;
      } else {
        this.editor
          .chain()
          .focus()
          .setHeading({ level: this.headingSelected.level })
          .run();
      }
    },
    async insertarVariable(variable) {
      if (!variable) return;
      if (this.usaAdicionales) {
        this.$emit("getAdicionales", variable.protoId);
        return;
      }
      this.editor.commands.insertContent(variable);
    },
    insertImageFromUrl(url) {
      this.editor
        .chain()
        .focus()
        .setImage({ src: url })
        .run();
      this.showInputImage = false;
    },
    async onUpdatedFile(archivoSubidoId) {
      this.asIdListTmp.push(archivoSubidoId);
      let imgUploaded = await axios.get(
        `api/archivo/get-img?asId=${archivoSubidoId}`
      );
      this.urlImg = `${imgUploaded.config.baseURL}${imgUploaded.config.url}`;
      if (this.urlImg) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: this.urlImg })
          .run();
      } else {
        console.log("no se pudo insertar");
      }
      this.showInputImage = false;
      this.isLoading = false;
    },

    onDictado() {
      this.$emit("onDictado");
    },
    openModalImage() {
      this.showInputImage = true;
    },
    cancelTextEditor() {
      if (this.asIdListTmp.length) {
        this.asIdListTmp.forEach(async as => {
          const res = await this.deleteImgUploaded(as);
          if (!res) {
            setTimeout(async () => {
              await this.deleteImgUploaded(as);
            }, 1000);
          }
        });
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.input-resize {
  border: 1px solid #a3a3a3b7;
  border-radius: 4px;
  position: relative;
  max-height: 85px;
}
.btn-edit-size {
  position: absolute;
  z-index: 1;
  right: 0px;
}
.size-label {
  margin-bottom: 2px !important;
  font-size: 14px !important;
}
.theme--light.v-input--is-disabled input {
  color: #0e0d0d;
}
.text-editor-wraper {
  box-shadow: 0px 3px 4px var(--green-color-shadow);
  margin-bottom: 8px;
  //border-radius: 3px;
  // min-height: 300px;
}
.max-h {
  max-height: 50px;
}
.min-h {
  min-height: 45px;
}
.heading-combo {
  max-width: 105px;
  font-size: 14px;
}
.heading-combo ::v-deep .v-input__slot {
  max-height: 25px !important;
}
.btn-dictar {
  font-size: 12px !important;
  min-height: 40px;
}
.toolbar .v-btn {
  font-size: 10px;
}
.toolbar {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
  background-color: #c7c7c751;
  //border: 1px solid #c7c7c77d;
  border-radius: 3px;
  box-shadow: -1px 2px 2px -1px rgba(106, 132, 134, 0.78);
}

.toolbar .v-btn {
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.combo-variables {
  max-width: 280px;
}
.is-active {
  color: #000000fe !important;
  background-color: rgba(201, 201, 201, 0.66) !important;
  font-weight: 900;
  box-shadow: -1px 4px 2px -1px rgba(106, 132, 134, 0.78);
  border: 1px solid #0e0d0d5e !important;
  font-size: 12px !important;
}
/* Basic editor styles */
.tiptap {
  min-height: 280px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 10px 10px;
  p {
    margin-bottom: 2px;
  }
  > * + * {
    margin-top: 0.1em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 0.8;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }

    ul li,
    ol li {
      display: list-item;
    }

    ul[data-type="taskList"] > li {
      display: flex;
    }
  }
}
mark {
  background-image: none;
  padding: 0.125em 0;
  border-radius: 0.25em;
  box-decoration-break: clone;
}
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.contenedor-lista {
  background-color: #ffffff;

  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.72);
}
.contenedor-lista div {
  cursor: pointer;
}
.li-disabled {
  color: rgba(67, 66, 66, 0.567);
  cursor: context-menu !important;
}
.cuadricula {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(7, 1fr);
  gap: 0px;
  max-height: 170px;
  max-width: 300px;
  margin: 0 auto;
}

.cuadrado {
  width: 18px;
  height: 18px;
  max-width: 100%;
  border-radius: 3px;
  text-align: center;
  border: 1px solid var(--green-color-shadow);
}
.cuadrado.hovered {
  background-color: #b3e0ff; /* Color de fondo al hacer hover, ajusta según tus preferencias */
}
.max-w {
  max-width: 20px;
}
</style>
